import React, { useState } from "react";
import "./navigation.scss";
import { Link }  from 'react-scroll'
import TwoColoredText from "../two-colored-text/two-colored-text.component";

const Navigation = ({routes}) => {
    const [showMobileMenu, setShowMobileMenu ] = useState(false);

    return (
        <>
            <nav className="navigation">
                <TwoColoredText blueText={"Mind"} greenText={"lify."} fontSize={24}/>
                <div className="navigation__menu">
                    {routes.map(({name, title}, index) => (
                        <Link activeClass="navigation__item--active"
                              className="navigation__item"
                              to={name}
                              spy={true}
                              smooth={true}
                              duration={500}
                              offset={-20}
                              key={index}>
                            {title}
                        </Link>
                    ))}
                </div>
                <div className="navigation__hamburger" onClick={(e) => setShowMobileMenu(!showMobileMenu)}>
                    <div className={showMobileMenu?'navigation__bar navigation__bar--transform':'navigation__bar'}></div>
                    <div className={showMobileMenu?'navigation__bar navigation__bar--transform':'navigation__bar'}></div>
                    <div className={showMobileMenu?'navigation__bar navigation__bar--transform':'navigation__bar'}></div>
                </div>
            </nav>
            <nav className={showMobileMenu?'navigation__mobile navigation__mobile--transform':'navigation__mobile'}>
                <div className="navigation__menu--mobile">
                    {routes.map(({name, title}, index) => (
                        <Link activeClass="navigation__item--active"
                              className="navigation__item navigation__item--mobile"
                              to={name}
                              spy={true}
                              smooth={true}
                              duration={500}
                              offset={-20}
                              key={index}
                            onClick={(e) => setShowMobileMenu(false)}>
                            {title}
                        </Link>
                    ))}
                </div>
            </nav>
        </>
    );
};

export default Navigation;
