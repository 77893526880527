import React from "react";

const FacebookSVG = ({height, width}) => (
    <svg viewBox="416 290 10 15" height={height} width={width}>
        <g id="Group_75" transform="translate(393 22.213)">
            <path id="Path_71" className="st0" d="M31.8,267.9h-2c-1.9-0.1-3.5,1.3-3.6,3.2c0,0.2,0,0.3,0,0.5v1.7h-2c-0.2,0-0.3,0.1-0.3,0.3l0,0
		v2.5c0,0.2,0.1,0.3,0.3,0.3l0,0h2v6.3c0,0.2,0.1,0.3,0.3,0.3l0,0h2.6c0.2,0,0.3-0.1,0.3-0.3l0,0v-6.3h2.3c0.2,0,0.3-0.1,0.3-0.3
		l0,0v-2.5c0-0.2-0.1-0.3-0.3-0.3l0,0h-2.3v-1.5c0-0.7,0.2-1.1,1.1-1.1h1.3c0.2,0,0.3-0.1,0.3-0.3v-2.3
		C32.1,268,32,267.9,31.8,267.9L31.8,267.9z"/>
        </g>
    </svg>
);

export default FacebookSVG;
