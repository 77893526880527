import React from "react";
import { Element } from 'react-scroll'
export const Router = ({routes}) => (
    <>
        {routes.map(({ name, Component }, index) => (
                <Element name={name} key={index}>
                    <Component/>
                </Element>
            )
        )}
    </>
);
