import React from "react";
import TwoColoredText from "../two-colored-text/two-colored-text.component";
import EmailSVG from "../svg/email.svg.component";
import FacebookSVG from "../svg/facebook.svg.component";
import "./contact.scss";

const Contact = () => {
    return (
        <section className="contact">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <TwoColoredText blueText={'Kontakt'} greenText={'.'} fontSize={30}/>
                        <ul className="list">
                            <li className="text--primary"><span><strong>Damian Czarnota</strong></span></li>
                            <li className="list__item text--primary"><EmailSVG height={16} width={16}/>
                                <a href={"mailto:kontakt@mindlify.pl"} className="contact__item">kontakt@mindlify.pl</a></li>
                            <li className="list__item text--primary"><FacebookSVG height={16} width={16}/>
                                <a href="https://www.facebook.com/mindlify.cloud" target={"_blank"} rel="noopener noreferrer"
                                   className="contact__item">mindlify.cloud</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contact;
