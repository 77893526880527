import React from "react";
import "./two-colored-text.scss";

const TwoColoredText = ({greenText, blueText, fontSize}) => (
    <div className="logo" style={{fontSize: fontSize + 'px'}}>
        <span className="logo__blue">{blueText}</span><span className="logo__green">{greenText}</span>
    </div>
);

export default TwoColoredText;
