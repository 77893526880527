import React from "react";

const EmailSVG = ({height, width}) => (
    <svg viewBox="416 290 10 15" height={height} width={width}>
        <g id="Group_65" transform="translate(0 0)">
            <path id="Path_63" className="st0" d="M415.5,295.5c0.2,0.1,0.7,0.5,1.5,1.1c0.9,0.6,1.5,1.1,2,1.4l0.3,0.2c0.2,0.1,0.3,0.2,0.4,0.3
		c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0,0.2,0.1,0.4,0.1l0,0c0.1,0,0.3,0,0.4-0.1c0.2-0.1,0.3-0.1,0.4-0.2
		c0.2-0.1,0.3-0.2,0.4-0.2c0.1-0.1,0.2-0.2,0.4-0.3s0.3-0.2,0.3-0.2l3.5-2.5c0.4-0.2,0.7-0.6,0.9-0.9c0.2-0.3,0.4-0.7,0.4-1.1
		c0-0.7-0.5-1.2-1.2-1.2h-11.1c-0.3,0-0.7,0.1-0.9,0.4c-0.2,0.3-0.3,0.6-0.3,1s0.2,0.7,0.4,1C414.9,294.9,415.2,295.2,415.5,295.5z"
            />
            <path id="Path_64" className="st0" d="M427,296.4c-1.6,1.1-2.9,2-3.8,2.6c-0.3,0.2-0.5,0.4-0.7,0.5s-0.5,0.3-0.7,0.4
		c-0.3,0.1-0.5,0.2-0.8,0.2l0,0c-0.3,0-0.6-0.1-0.8-0.2c-0.2-0.1-0.5-0.2-0.7-0.4c-0.2-0.1-0.4-0.3-0.7-0.5
		c-0.7-0.5-1.9-1.4-3.7-2.6c-0.3-0.2-0.5-0.4-0.8-0.7v6c0,0.3,0.1,0.6,0.4,0.9c0.2,0.2,0.5,0.4,0.9,0.4h11.1c0.7,0,1.2-0.5,1.2-1.2
		v-6C427.5,295.9,427.3,296.1,427,296.4z"/>
        </g>
    </svg>
);

export default EmailSVG;
