import React from "react";
import "./about.scss";
import profileImage from "../../images/profile_photo.jpg";

const AboutMe = () => (
    <section className='container team'>
        <div className="row team__background">
            <div className="col-12">
                <div className="team__image">
                    <img src={profileImage} alt="Damian Czarnota - zdjęcie profilowe"/>
                </div>
            </div>
            <div className="col-12">
                <p className="text--primary"><span
                    className="text--important">Cześć,</span><br/>
                    Nazywam się Damian Czarnota.</p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 text--primary d-flex flex-column text-justify">
                <p>Od 5 lat programuję, swoje umiejętności zdobywam i&nbsp;poprawiam pod okiem bardziej doświadczonych back-end i&nbsp;front-end developerów.
                    Pracowałem przy wielu projektach z&nbsp;różnych branż, w tym ubezpieczeniowej, psychologicznej czy logistycznej.
                    Tworzę aplikacje/strony internetowe od zera, według wytycznych otrzymanych od Klienta.
                    Oferuję również consulting – pomagam przy wycenach, tłumaczę, czym jest optymalizacja WWW oraz wspomagam prowadzenie własnej strony internetowej.</p>
                <span>Jeżeli szukasz ambitnego programisty – trafiłeś w dziesiątkę.</span>
                <span className="mt-3"><strong>Nie istnieją dla mnie rzeczy niemożliwe – są tylko łatwe i&nbsp;trudniejsze.</strong></span>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
                <p className="text--primary text-justify"><strong>Mindlify</strong> jest moim pomysłem na
                    przedsiębiorstwo, w którym będę mógł realizować się zawodowo - jako programista i&nbsp;manager.</p>
                <p className="text--primary text-justify"><strong>W działalności stawiam na ciąłgy kontakt z klientem,
                    realiazację projektów w założonym terminie i dostarczaniu produktu jak najwyższej
                    jakości.</strong></p>
                <p className="text--primary text-justify">Technologie w jakich pracuję to: Angular, React, GatsbyJS, NestJS, Java, Spring</p>
            </div>
        </div>
    </section>
);

export default AboutMe;
