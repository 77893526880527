import React from "react";
import "./head.scss";
import AboutMe from "../about/about.component";

const Head = () => (
    <header>
        <div className="head container">
            <div className="row">
                <div className="col-12 d-flex align-content-end">
                    <AboutMe />
                </div>
            </div>
        </div>
    </header>
);

export default Head;
