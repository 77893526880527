import React from "react";
import "./partners.scss";
import TwoColoredText from "../two-colored-text/two-colored-text.component";
import therapify from "../../images/therapify.png";
import sii from "../../images/logo-sii.png";
import ready from "../../images/ready4s.png";
import comarch from "../../images/comarch.png";
import ApreelSVG from "../svg/apreel.svg.component";

const Partners = () => (
    <section className="partners mb-5">
        <div className="container-fluid">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <TwoColoredText blueText={'Doświadczenie'} greenText={'.'} fontSize={30}/>
                    </div>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-12">
                    <div className="row partners__background">
                        <div className="container">
                            <div className="row">
                                <div className="partners__items flex-wrap flex-xl-nowrap">
                                    <a className="partners__item" href={'https://apreel.com/'} target={"_blank"} rel="noopener noreferrer">
                                        <ApreelSVG />
                                    </a>
                                    <a className="partners__item" href={'https://therapify.eu/'} target={"_blank"} rel="noopener noreferrer">
                                        <img src={therapify} alt={'Logo Therapify - Aplikacji umożliwiającej odbycie wizyty u lekarza zdrowia psychicznego'} />
                                    </a>
                                    <a className="partners__item" href={'https://sii.pl/'} target={"_blank"} rel="noopener noreferrer">
                                        <img src={sii} alt={'Logo Sii'} />
                                    </a>
                                    <a className="partners__item" href={'https://ready4s.com/'} target={"_blank"} rel="noopener noreferrer">
                                        <img src={ready} alt={'Logo Ready4S - software house'} />
                                    </a>
                                    <a className="partners__item" href={'https://comarch.pl/'} target={"_blank"} rel="noopener noreferrer">
                                        <img src={comarch} alt={'Logo Comarch'} />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
);

export default Partners;
