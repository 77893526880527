import React from 'react';
import './Wrapper.scss';
import Navigation from "../components/navigation/navigation.component";
import Head from "../components/head/head.component";
import { Router } from "../components/router/router.component";
import Partners from "../components/partners/partners.component";
import Contact from "../components/contact/contact.component";

const Wrapper = () => {
    const routes = [
        { name: 'HOME_PAGE', title: "Strona główna", Component: Head },
        { name: 'PARTNERS', title: "Doświadczenie", Component: Partners },
        { name: 'CONTACT', title: "Kontakt", Component: Contact },
    ];

  return (
    <>
      <header>
        <Navigation routes={routes}/>
      </header>
      <Router routes={routes}/>
    </>
  );
}

export default Wrapper;
